.hero-img-wrapper {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.75) 50%
    ),
    url("../../../assets/mainItems/heroImg.png");

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  height: 60vh;
  background-attachment: fixed;
  background-position: center;
  background-position-y: 65%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero-img-content {
  display: flex;
  justify-content: center;
}

.hero-img-title {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 5px 10px 10px 10px;
  font-size: 1.3rem;
}

.hero-img-title > * {
  font-weight: 500;
}

.options-wrapper {
  position: absolute;
  top: 55vh;
  display: flex;
  justify-content: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.mobile-options-wrapper {
  width: 100%;
  height: fit-content;
  padding-top: 20px;
}

.mobile-options {
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;
  justify-content: space-between;
}

.mobile-options > * {
  flex-basis: calc(33.33% - 10px); /* Three items per row with 10px spacing */
  margin-right: 10px;
  margin-bottom: 10px;
  min-width: 150px;
  max-width: fit-content;
}

.mobile-options > *:nth-child(3n) {
  margin-right: 0;
}

@media (min-width: 778px) {
  .hero-img-wrapper {
    height: 90vh;
  }

  .options-wrapper {
    position: absolute;
    top: 80vh;
    display: flex;
    justify-content: center;
  }

  .hero-img-title {
    display: flex;
    font-size: 2.5rem;
  }
}

@media only screen and (max-device-width: 1px) {
  .hero-img-wrapper {
    background-attachment: scroll;
  }
}

/* Disable parallax on Safari */
@supports (-webkit-touch-callout: none) {
  /* Use a Safari-specific CSS property to disable background-attachment */
  .hero-img-wrapper {
    background-attachment: scroll !important; /* Important to override the default value */
  }
}
