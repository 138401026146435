.menu-wrapper {
  display: flex;
  flex-direction: column;
  margin: 20px;
  padding: 20px;
}

.menu-title {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 10px;
}

.menu-icons-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (min-width: 778px) {
  .menu-wrapper {
    padding: 30px 20px;
    margin: 25px 6%;
  }

  .menu-title {
    font-size: 35px;
  }
}

@media (min-width: 778px) and (max-width: 1181px) {
  .menu-icons-wrapper {
    justify-content: flex-start;
    gap: 5%;
    flex-direction: row;
  }
}

@media (min-width: 1182px) {
  .menu-icons-wrapper {
    justify-content: flex-start;
    flex-direction: row;
    gap: 2%;
  }
}
